<template>
  <div class="backgroundcolor">
    <router-view></router-view>
  </div>
</template>

<script setup></script>
<style>
html {
  background: rgb(243 244 246);
  background: linear-gradient(
    to bottom,
    #509eb8 0%,
    #06d6a0 40%,
    #ffd166 100%
    );
    /* height: 230vh; */
    min-height: 100vh

  /* background: rgb(243, 244, 246);
  background: linear-gradient(
    to bottom,
    #509eb8 40vh,
    #06d6a0 70vh,
    #13e39d 90vh
    #ffd166 100vh
  );
  min-height: 100vh; */


  /* background: rgb(243 244 246);
  background: linear-gradient(
    to right,
    #9ed9f2,
    #a9e2f9,
    #9ed9f2,
    #b8e7fa,
    #9ed9f2,
    #b8e7fa
  ); */



  /* background-color: #b1b1b15b ; */
  /* background: linear-gradient(to right, #c5e4ce, rgb(6, 6, 12)); */

}

/* body {
  height: 100%;
  margin: 0;
} */
</style>
