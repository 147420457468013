<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw] xl:w-[51vw] 2xl:[52vw]  p-2 text-[1rem] lg:text-base xl:text-base 2xl:text-base">
    <p>Et si cet échange t’a plu, partage-le avec tes amis !</p>
    <div class="flex gap-5 lg:flex-row lg:gap-12 justify-center mt-8">
      <button @click="shareOnFacebook()" class="flex gap-1 mb-2 lg:mb-0 items-center">
        <p class="hidden xl:block">Facebook</p>
        <img class="md:w-18 md:h-18 md:mt-[-0.2rem] h-12 w-12 " src="../../assets/facebook.png" alt="facebook">
      </button>
      <button @click="shareOnTwitter()" class="flex mb-2 lg:mb-0 items-center">
        <p class="hidden xl:block">Twitter</p>
        <img class="md:w-18 md:h-18 md:mt-[-0.2rem] h-12 w-12 " src="../../assets/twitter.png" alt="twitter">
      </button>
      <button @click="shareOnWhatsApp()" class="flex gap-1 mb-2 lg:mb-0 items-center">
        <p class="hidden xl:block">WhatsApp</p>
        <img class="md:w-18 md:h-18 md:mt-[-0.2rem] h-12 w-12 " src="../../assets/whatsapp.png" alt="shareOnWhatsApp">
      </button>
      <button @click="shareOnInstagram()" class="flex gap-1 mb-2 lg:mb-0 items-center">
        <p class="hidden xl:block">Instagram</p>
        <img class="md:w-18 md:h-18 md:mt-[-0.2rem] h-12 w-12 " src="../../assets/instagram.png" alt="instagram">
      </button>
      <button @click="shareOnTiktok()" class="flex gap-1 mb-2 lg:mb-0 items-center mr-8">
        <p class="hidden xl:block">Tiktok</p>
        <img class="md:w-18 md:h-18 md:mt-[-0.2rem] h-12 w-12 " src="../../assets/tiktok.png" alt="tiktok">
      </button>
    </div>
  </div>
</template>


<script setup>




const chatbotUrl = "https://meilleuresoffresmobiles.fr";


const shareOnFacebook = async () => {
  const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(chatbotUrl)}`;
  window.open(facebookShareLink, "_blank");
};

const shareOnTwitter = async () => {
  const textToTweet = "Meilleures offres disponibles";
  const twitterShareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(chatbotUrl)}&text=${encodeURIComponent(textToTweet)}`;
  window.open(twitterShareLink, "_blank");
};

const shareOnWhatsApp = async () => {
  const whatsappShareLink = `https://wa.me/?text=${encodeURIComponent("Découvrez mon chatbot incroyable! " + chatbotUrl)}`;
  window.open(whatsappShareLink, "_blank");
};

const shareOnInstagram = async () => {
  const instagramProfileLink = "https://www.instagram.com/VOTRE_NOM_UTILISATEUR/";
  window.open(instagramProfileLink, "_blank");
};

const shareOnTiktok = async () => {
  const tiktokShareLink = `https://www.tiktok.com/share?url=${encodeURIComponent(chatbotUrl)}`;
  window.open(tiktokShareLink, "_blank");

};


</script>