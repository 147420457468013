import { createApp } from 'vue'
import router  from './router'
import App from './App.vue'
import "./main.css";
import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.delete['Content-Type'] = 'application/json';


createApp(App).use(router).mount('#app')
const BASE_URL = "https://meilleuresoffresmobiles.fr";
const BACK_URL = "https://meilleuresoffresmobiles.fr";

const config = {
    BASE_URL,
    BACK_URL
}

export default config; 
