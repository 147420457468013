<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <!-- Entête de l'interface de conversation -->
  <chatHeader />

  

  <main ref="pageContainer">
    <!-- Interface de discussion -->
    <div class="pb-[10rem] md:max-w-2xl lg:max-w-5xl mx-auto">
      <div class="mx-4">
        <div class="inline-block bg-white p-4 m-1">
    <div>
      <p>
        (<span class="text-black text-[1rem] font-bold">*</span>) Champs
        obligatoires
      </p>
    </div>
    <div class="flex flex-col mt-3 w-full">
      <!-- Form1 -->
      <div class="flex border-2 gap-2 rounded-lg p-3">
        <div class="mb-4 w-full md:w-[49%] lg:w-[49%]">
          <label
            for="telephone"
            class="block text-[1rem] font-medium text-gray-600"
            ><span class="text-black text-[1rem] font-bold">*</span> Téléphone
            :</label
          >
          <input
            id="telephone"
            class="mt-1 p-1 border-2 border-solid rounded-md w-full text-[1rem]"
            placeholder="veuillez entrer votre numéro"
            type="tel"
            v-model="numero_client"
          />
          <p v-if="numeroError" class="text-red-500 text-[1rem]">
            {{ numeroError }}
          </p>
        </div>
        <div class="mb-4 w-full md:w-[49%] lg:w-[49%]">
          <label for="email" class="block text-[1rem] font-medium text-gray-600"
            ><span class="text-black text-[1rem] font-bold">*</span> Email
            :</label
          >
          <input
            id="email"
            class="mt-1 p-1 border-2 border-solid rounded-md w-full text-[1rem]"
            placeholder="Veuillez entrer votre mail"
            type="email"
            v-model="email_client"
          />
          <p v-if="emailError" class="text-red-500 text-[1rem]">
            {{ emailError }}
          </p>
        </div>
      </div>
      <div class="flex justify-center">
        <button
          v-if="showSuivantButton"
          @click="nextStep()"
          class="bg-cyan-600 text-white m-1 mb-1 p-1 rounded-md"
        >
          Suivant
        </button>
      </div>
      <!-- Form2 -->
      <div
        v-if="currentStep"
        class="mt-[1.5rem] text-justify flex flex-col mb-1"
      >
        <p class="mb-1 font-bold">Un instant, la surprise arrive... 🎁</p>
        <div class="mb-[0.75rem]">
          <input
            id="firstAgreement"
            type="checkbox"
            class="mr-2"
            v-model="firstAgreement"
          />
          <label for="firstAgreement" class="text-[1rem]">
            En cliquant sur
            <span class="underline">« Je découvre mon offre »</span>
            tu donnes ton accord pour être contacté(e) par e-mail, téléphone,
            sms, whatsapp par VippData ou l'un de ses partenaires pour une
            présentation personnalisée, conformément à nos
            <span class="underline"
              ><a
                target="_blank"
                href="/conditionsgenerales"
                class="underline text-blue-700"
                >CGU</a
              ></span
            >.
          </label>
        </div>
        <div class="text-[1rem] mb-[0.75rem]">
          Tes données sont recueillies pour permettre à VippData et à ses
          partenaires de te recontacter pour te présenter une proposition
          adaptée à tes besoins. Pour en savoir plus sur la gestion de tes
          données personnelles et l'exercice de tes droits, rends-toi sur la
          page

          <a
            class="text-blue-500 break-words"
            target="_blank"
            href="https://meilleuresoffresmobiles.fr/politiqueConfidentialite"
          >
            polititique de confidentialité.
          </a>
        </div>
        <div class="text-[1rem] font-bold text-center">
          Ce contact est entièrement gratuit et sans aucun engagement.
        </div>
      </div>
      <p v-if="showCGUmsg" class="text-red-600 text-[1rem]">
        Merci de prendre connaissance de nos
        <span class="underline">CGU</span> avant d'accéder à votre offre.
      </p>
    </div>
  </div>
        <!-- partie pour la gestion du fil de discussion -->
        <section
          v-for="(message, index) in chatHistory"
          :key="index"
          class="flex"
        >
          <!-- partie affichant les messages du bot -->
          <section v-if="message.role == 'bot'" class="">
            <!-- affiche les phrases d'introduction du bot -->
            <div v-show="message.welcomeMsg.length > 0" class="">
              <div class="flex flex-col gap-1 contenu">
                <div
                  class="flex items-center gap-6"
                  v-for="(value, index) in message.welcomeMsg"
                  :key="index"
                >
                  <img
                    class="del w-8 h-8 lg:w-10 lg:h-10 opacity-0 m-0"
                    src="../assets/leo.png"
                    alt="mascotteTigre"
                  />
                  <!-- <p class="bg-blue-500 w-8 h-8 lg:w-10 lg:h-10 opacity-0"></p> -->
                  <p class="chat-bubble p-2 text-[1rem]">
                    {{ value }}
                  </p>
                </div>
              </div>
            </div>
            <!-- affiche les affirmations et la question posées par le bot -->
            <div class="message flex items-center gap-6">
              <div class="flex flex-col gap-1">
                <div v-if="message.affirmation.length > 0" class="flex gap-6">
                  <!-- <p class="bg-blue-500 w-8 h-8 lg:w-10 lg:h-10 opacity-0"></p> -->
                  <img
                    class="del w-8 h-8 lg:w-10 lg:h-10 opacity-0 m-0"
                    src="../assets/leo.png"
                    alt="mascotteTigre"
                  />
                  <div class="flex flex-col gap-1">
                    <div
                      v-for="(value, index) in message.affirmation"
                      :key="index"
                    >
                      <div class="chat-bubble p-2 text-[1rem]">
                        {{ value }}
                      </div>
                      <p
                        v-if="
                          value ==
                          'Casquette de détective activée 🧢 pour dénicher ton offre ! 🧐'
                        "
                        class="flex justify-center items-center"
                      >
                        <img
                          class="w-32 h-32 lg:w-48 lg:h-48 rounded-md"
                          src="../assets/mascottesleo/leoremov.png"
                          alt="leo"
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex gap-6">
                  <img
                    class="w-8 h-8 lg:w-10 lg:h-10 object-cover m-0 mt-2"
                    :class="{
                      'mt-6':
                        message.question ==
                        'Quelle marque de téléphone 📱 te ferait plaisir ?',
                    }"
                    src="../assets/leo.png"
                    alt="mascotteTigre"
                  />
                  <p class="chat-bubble p-2 text-[1rem] mt-1">
                    {{ message.question }}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <!-- partie affichant les messages de l'utilisateur -->
          <section
            v-else
            class="message animation_chat message-right mt-2 mb-1 w-full"
          >
            <div class="chat-bubble answer text-white p-2 text-[1rem]">
              {{ message.content }}
            </div>
          </section>
        </section>
        <!-- partie pour l'ajout dynamique des questions et suggestions de réponse du bot -->
        <div class="">
          <!-- ***** Message d'ouverture de Léo (Début) **** -->
          <section v-show="beginMsg.length > 0" class="flex flex-col gap-1">
            <!--***** Premier message *****-->
            <section v-if="show1" class="flex flex-col gap-3 p-0">
              <div class="flex items-center gap-6">
                <img
                  :class="{ 'opacity-0': show2 }"
                  class="opacity-1 del animation_chat w-8 h-8 lg:w-10 lg:h-10 m-0"
                  src="../assets/leo.png"
                  alt="mascotteTigre"
                />
                <p class="chat-bubble animation_chat p-2 text-[1rem]">
                  Bonjour, c'est Léo ton bot mobile. 😊
                </p>
              </div>
              <Chat_loader
                v-if="!show2 && !show3 && !showBotAnswer"
                class="ml-16"
              />
            </section>
            <!--***** Deuxième message *****-->
            <section v-if="show2" class="flex flex-col gap-3">
              <div class="flex items-center gap-6">
                <img
                  :class="{ 'opacity-0': show3 }"
                  class="opacity-1 animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                  src="../assets/leo.png"
                  alt="mascotteTigre"
                />
                <p class="chat-bubble animation_chat p-2 text-[1rem]">
                  Tu souhaites un nouveau téléphone ? Reste avec moi jusqu'au
                  bout ! 🤗
                </p>
              </div>
              <Chat_loader v-if="!show3 && show2 && show1" class="ml-16" />
            </section>
            <!--***** Troisième message *****-->
            <section v-if="show3" class="flex flex-col gap-3 mb-1">
              <div class="flex items-center gap-6">
                <img
                  :class="{ 'opacity-0': showBotAnswer }"
                  class="opacity-1 animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                  src="../assets/leo.png"
                  alt="mascotteTigre"
                />
                <p class="chat-bubble animation_chat p-2 text-[1rem]">
                  Faisons connaissance... 😉
                </p>
              </div>
              <Chat_loader
                v-if="show3 && show2 && show1 && !showBotAnswer"
                class="ml-16"
              />
            </section>
          </section>
          <!-- ***** Message d'ouverture de Léo (Fin) **** -->
          <!-- cette partie affichera les messages du bot -->
          <div class="">
            <div class="">
              <Chat_loader v-if="showAnswerLoader" class="ml-16" />
            </div>
            <!-- ***** Question et suggestion de réponses du bot (Début) **** -->
            <section v-if="showBotAnswer" class="">
              <div>
                <!-- affiche les affirmations du bot -->
                <section v-if="showBotAnswer" v-show="botAnswer.length > 0">
                  <section class="flex flex-col gap-1">
                    <section class="flex flex-col gap-3">
                      <div
                        v-if="showAffirmation1"
                        class="flex items-center gap-6"
                      >
                        <img
                          :class="{ 'opacity-0': showAffirmation2 }"
                          class="w-8 h-8 lg:w-10 lg:h-10 m-0"
                          src="../assets/leo.png"
                          alt="mascotteTigre"
                        />
                        <p
                          class="chat_bubble_question animation_chat p-2 text-[1rem]"
                        >
                          {{ showAffirmation }}
                        </p>
                      </div>
                      <Chat_loader
                        v-if="!showAffirmation2 && showAffirmation1"
                        class="ml-16"
                      />
                    </section>
                    <section class="flex flex-col gap-3">
                      <div
                        v-if="showAffirmation2 && botAnswer.length > 1"
                        class="flex items-center gap-6"
                      >
                        <img
                          :class="{ 'opacity-0': showQuestion }"
                          class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                          src="../assets/leo.png"
                          alt="mascotteTigre"
                        />
                        <p
                          class="chat_bubble_question animation_chat text-[1rem] p-2"
                        >
                          On continue à faire connaissance...
                        </p>
                      </div>
                      <Chat_loader
                        v-if="
                          showAffirmation1 && showAffirmation2 && !showQuestion
                        "
                        class="ml-16"
                      />
                    </section>
                  </section>
                  <section v-if="showAffirmation3" class="flex flex-col gap-3">
                    <div class="flex items-center gap-6">
                      <img
                        :class="{ 'opacity-0': showQuestion }"
                        class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                        src="../assets/leo.png"
                        alt="mascotteTigre"
                      />
                      <p
                        class="chat_bubble_question animation_chat text-[1rem] p-2"
                      >
                        {{ showAffirmation }}
                      </p>
                    </div>
                    <Chat_loader
                      v-if="!showQuestion && showAffirmation3"
                      class="ml-16"
                    />
                  </section>
                  <section v-if="showAffirmation4" class="flex flex-col gap-3">
                    <div class="flex items-center gap-6">
                      <img
                        :class="{ 'opacity-0': showQuestion }"
                        class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                        src="../assets/leo.png"
                        alt="mascotteTigre"
                      />
                      <p
                        class="chat_bubble_question animation_chat text-[1rem] p-2"
                      >
                        {{ showAffirmation }}
                      </p>
                    </div>
                    <Chat_loader
                      v-if="!showQuestion && showAffirmation4"
                      class="ml-16"
                    />
                  </section>
                  <section class="flex flex-col gap-1">
                    <section
                      v-if="showAffirmation5"
                      class="flex flex-col gap-3"
                    >
                      <div class="flex items-center gap-6">
                        <img
                          :class="{ 'opacity-0': showAffirmation6 }"
                          class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                          src="../assets/leo.png"
                          alt="mascotteTigre"
                        />
                        <p
                          class="chat_bubble_question animation_chat text-[1rem] p-2"
                        >
                          Top ! Merci.
                        </p>
                      </div>
                      <Chat_loader
                        v-if="
                          showAffirmation5 &&
                          !showAffirmation6 &&
                          !showAffirmation7 &&
                          !showQuestion
                        "
                        class="ml-16"
                      />
                    </section>
                    <section
                      v-if="showAffirmation6"
                      class="flex flex-col gap-3"
                    >
                      <div>
                        <div class="flex items-center gap-6">
                          <img
                            :class="{ 'opacity-0': showQuestion }"
                            class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                            src="../assets/leo.png"
                            alt="mascotteTigre"
                          />
                          <div
                            class="chat_bubble_question animation_chat text-[1rem] p-2"
                          >
                            Casquette de détective activée 🧢 pour dénicher ton
                            offre ! 🧐
                          </div>
                        </div>
                        <p class="flex justify-center items-center">
                          <img
                            class="w-32 h-32 lg:w-48 lg:h-48 rounded-md"
                            src="../assets/mascottesleo/leoremov.png"
                            alt="Détective"
                          />
                        </p>
                      </div>
                      <Chat_loader
                        v-if="
                          showAffirmation5 && showAffirmation6 && !showQuestion
                        "
                        class="ml-16"
                      />
                    </section>
                  </section>
                </section>
                <div
                  class="animation_chat message-left flex items-center gap-6"
                >
                  <img
                    v-if="showQuestion"
                    class="del animation_chat w-8 h-8 lg:w-10 lg:h-10 m-0"
                    src="../assets/leo.png"
                    alt="mascotteTigre"
                  />
                  <div
                    v-if="showQuestion"
                    class="chat_bubble_question p-2 text-[1rem]"
                  >
                    <p class="">{{ firstQuestion }}</p>
                    <div class="">
                      <!-- Formulaire découverte offre -->
                      <div
                        v-if="isOfferForm"
                        class="inline-block bg-white p-4 m-1"
                      >
                        <div>
                          <p>
                            (<span class="text-black text-[1rem] font-bold"
                              >*</span
                            >) Champs obligatoires
                          </p>
                        </div>
                        <div class="flex flex-col mt-3 w-full">
                          <!-- Form1 -->
                          <div class="flex border-2 gap-2 rounded-lg p-3">
                            <div class="mb-4 w-full md:w-[49%] lg:w-[49%]">
                              <label
                                for="telephone"
                                class="block text-[1rem] font-medium text-gray-600"
                                ><span class="text-black text-[1rem] font-bold"
                                  >*</span
                                >
                                Téléphone :</label
                              >
                              <input
                                id="telephone"
                                class="mt-1 p-1 border-2 border-solid rounded-md w-full text-[1rem]"
                                placeholder="veuillez entrer votre numéro"
                                type="tel"
                                v-model="numero_client"
                              />
                              <p
                                v-if="numeroError"
                                class="text-red-500 text-[1rem]"
                              >
                                {{ numeroError }}
                              </p>
                            </div>
                            <div class="mb-4 w-full md:w-[49%] lg:w-[49%]">
                              <label
                                for="email"
                                class="block text-[1rem] font-medium text-gray-600"
                                ><span class="text-black text-[1rem] font-bold"
                                  >*</span
                                >
                                Email :</label
                              >
                              <input
                                id="email"
                                class="mt-1 p-1 border-2 border-solid rounded-md w-full text-[1rem]"
                                placeholder="Veuillez entrer votre mail"
                                type="email"
                                v-model="email_client"
                              />
                              <p
                                v-if="emailError"
                                class="text-red-500 text-[1rem]"
                              >
                                {{ emailError }}
                              </p>
                            </div>
                          </div>
                          <div class="flex justify-center">
                            <button
                              v-if="showSuivantButton"
                              @click="nextStep()"
                              class="bg-cyan-600 text-white m-1 mb-1 p-1 rounded-md"
                            >
                              Suivant
                            </button>
                          </div>
                          <!-- Form2 -->
                          <div
                            v-if="currentStep"
                            class="mt-[1.5rem] text-justify flex flex-col mb-1"
                          >
                            <p class="mb-1 font-bold">
                              Un instant, la surprise arrive... 🎁
                            </p>
                            <div class="mb-[0.75rem]">
                              <input
                                id="firstAgreement"
                                type="checkbox"
                                class="mr-2"
                                v-model="firstAgreement"
                              />
                              <label for="firstAgreement" class="text-[1rem]">
                                En cliquant sur
                                <span class="underline"
                                  >« Je découvre mon offre »</span
                                >
                                tu donnes ton accord pour être contacté(e) par
                                e-mail, téléphone, sms, whatsapp par VippData ou
                                l'un de ses partenaires pour une présentation
                                personnalisée, conformément à nos
                                <span class="underline"
                                  ><a
                                    target="_blank"
                                    href="/conditionsgenerales"
                                    class="underline text-blue-700"
                                    >CGU</a
                                  ></span
                                >.
                              </label>
                            </div>
                            <div class="text-[1rem] mb-[0.75rem]">
                              Tes données sont recueillies pour permettre à
                              VippData et à ses partenaires de te recontacter
                              pour te présenter une proposition adaptée à tes
                              besoins. Pour en savoir plus sur la gestion de tes
                              données personnelles et l'exercice de tes droits,
                              rends-toi sur la page

                              <a
                                class="text-blue-500 break-words"
                                target="_blank"
                                href="https://meilleuresoffresmobiles.fr/politiqueConfidentialite"
                              >
                                polititique de confidentialité.
                              </a>
                            </div>
                            <div class="text-[1rem] font-bold text-center">
                              Ce contact est entièrement gratuit et sans aucun
                              engagement.
                            </div>
                          </div>
                          <p v-if="showCGUmsg" class="text-red-600 text-[1rem]">
                            Merci de prendre connaissance de nos
                            <span class="underline">CGU</span> avant d'accéder à
                            votre offre.
                          </p>
                        </div>
                      </div>
                      <!-- Affiche les réponses basiques -->

                      <section
                        class="flex flex-wrap justify-center items-center gap-6 lg:gap-10 mt-4"
                      >
                        <div
                          class="flex flex-col justify-center items-center"
                          :disabled="chooseAnswer"
                          v-for="(value, index) in suggested_answers"
                          :key="index"
                        >
                          <button
                            v-if="
                              showAnswerButton &&
                              value.answer === 'Je découvre mon offre'
                            "
                            @click="getAnswerChosen(value.answer)"
                            :disabled="isDisabled"
                            :id="firstAgreement ? 'decouvreOffre' : ''"
                            :class="{
                              'hover:bg-red-500': !firstAgreement,
                              'bg-cyan-600': true,
                            }"
                            class="transition-all duration-500 ease-out font-semibold text-white p-2 w-[120px] md:w-[130px] lg:w-[200px] rounded-md text-[0.70rem] lg:text-[1rem]"
                            @mouseover="handleInteraction"
                            @mouseleave="handleMouseLeave"
                            @touchstart="handleInteraction"
                            @touchend="handleMouseLeave"
                          >
                            <span>{{ displayText }}</span>
                          </button>

                          <button
                            v-if="
                              showAnswerButton &&
                              value.answer !== 'Je découvre mon offre'
                            "
                            @click="getAnswerChosen(value.answer)"
                            class="transition-all duration-500 ease-in font-semibold text-white p-1 w-[128px] md:w-[138px] lg:w-[208px] lg:hover:bg-cyan-600 rounded-md"
                          >
                            <img
                              v-if="value.answerImg != null"
                              class="mx-auto object-cover w-[60px]"
                              :class="{ 'h-[120px]': value.answer === 'AUTRE' }"
                              :src="value.answerImg"
                              alt="mascotteTigre"
                            />
                            <span
                              class="bg-cyan-600 block rounded-md text-[0.90rem] p-2 lg:text-[1rem] w-[120px] md:w-[130px] lg:w-[200px]"
                              :class="{
                                'w-[131px]': value.answer == 'SAMSUNG Z-Flip',
                              }"
                            >
                              {{ value.answer }}
                            </span>
                          </button>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- ***** Question et suggestion de réponses du bot (Fin) **** -->
          </div>
        </div>
        <div class="flex flex-col gap-1 mt-[0.25rem]" v-if="messageFinal">
          <section v-if="showAffirmation7" class="flex flex-col gap-3">
            <div class="flex items-center gap-6">
              <img
                :class="{ 'opacity-0': showAffirmationTadaam }"
                class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                src="../assets/leo.png"
                alt="mascotteTigre"
              />

              <div
                class="chat_bubble_question animation_chat w-[75vw] sm:w-[58vw] md:w-[54vw] p-2 text-[1rem]"
              >
                <div class="flex gap-4 items-center">
                  <span class="mr-1">Enquête en cours </span>
                  <span class="spinner"></span>
                  <span class="ml-1">👮‍♀️</span>
                </div>
              </div>
            </div>
            <Chat_loader
              v-if="messageFinal && showAffirmation7 && !showAffirmationTadaam"
              class="ml-16"
            />
          </section>

          <section v-if="showAffirmationTadaam" class="flex flex-col gap-3">
            <div>
              <div class="flex items-center gap-6">
                <img
                  :class="{ 'opacity-0': messageFin5 }"
                  class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                  src="../assets/leo.png"
                  alt="mascotteTigre"
                />
                <p
                  class="chat_bubble_question animation_chat w-[75vw] sm:w-[58vw] md:w-[54vw] p-2 text-[1rem]"
                >
                  <span class="text-xl lg:text-3xl font-bold">Tadaam !!!</span>
                </p>
              </div>
              <p class="flex justify-center items-center mt-1">
                <img
                  class="ml-12 w-32 h-32 lg:w-48 lg:h-48 rounded-md"
                  src="../assets/mascottesleo/leo1remove.png"
                  alt="Détective"
                />
              </p>
            </div>
            <Chat_loader
              v-if="messageFinal && showAffirmationTadaam && !messageFin5"
              class="ml-16"
            />
          </section>

          <section class="flex flex-col gap-3">
            <div class="flex items-center gap-6" v-if="messageFin5">
              <img
                :class="{ 'opacity-0': messageFin2 }"
                class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                src="../assets/leo.png"
                alt="mascotteTigre"
              />
              <div class="">
                <p
                  v-if="bouyguesImage"
                  class="chat_bubble_question animation_chat p-2 w-[75vw] sm:w-[58vw] md:w-[54vw] xl:w-[51vw] 2xl:[52vw] text-[1rem]"
                >
                  Ton offre la plus adaptée, c'est chez :
                  <img
                    class="mx-auto mt-[1rem] w-[88px] h-[88px]"
                    src="../assets/bouygues.png"
                    alt="mascotteTigre"
                  />
                </p>
              </div>
            </div>
            <Chat_loader
              v-if="messageFinal && messageFin5 && !messageFin2"
              class="ml-16"
            />
          </section>

          <section class="flex flex-col gap-3">
            <div class="flex items-center gap-6" v-if="messageFin2">
              <img
                :class="{
                  'opacity-0':
                    messageFinal && messageFin5 && messageFin2 && messageFin3,
                }"
                class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                src="../assets/leo.png"
                alt="mascotteTigre"
              />
              <p
                class="chat_bubble_question animation_chat text-[1rem] p-2"
                v-if="bouyguesImage"
              >
                {{ FinalmesssageTime }}
              </p>
              <p
                class="chat_bubble_question animation_chat text-[1rem] p-2"
                v-if="noImage"
              >
                {{ FinalmesssageTime }}
              </p>
            </div>
            <Chat_loader
              v-if="
                messageFinal &&
                messageFin5 &&
                messageFin &&
                messageFin2 &&
                !messageFin3
              "
              class="ml-16"
            />
          </section>

          <section
            class="flex flex-col gap-3"
            :class="{
              'mb-[-0.1rem]':
                FinalmesssageTime2 ==
                  'Prépare-toi à dégainer ton téléphone pour en profiter au plus vite 📞' &&
                noImage,
            }"
          >
            <div class="flex items-center gap-6" v-if="messageFin3">
              <img
                :class="{
                  'opacity-0':
                    bouyguesImage &&
                    (FinalmesssageTime2 ==
                      'Prépare-toi à dégainer ton téléphone pour en profiter au plus vite 📞' ||
                      FinalmesssageTime2 !=
                        'Prépare-toi à dégainer ton téléphone pour en profiter au plus vite 📞') &&
                    (messageFin4 || messageFin6),
                }"
                class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                src="../assets/leo.png"
                alt="mascotteTigre"
              />
              <p
                v-if="bouyguesImage"
                class="chat_bubble_question animation_chat text-[1rem] p-2"
              >
                {{ FinalmesssageTime2 }}
              </p>
              <p
                v-if="noImage"
                class="chat_bubble_question animation_chat text-[1rem] p-2"
              >
                {{ FinalmesssageTime2 }}
              </p>
            </div>
            <Chat_loader
              v-if="
                (bouyguesImage &&
                  messageFin2 &&
                  messageFin &&
                  messageFin5 &&
                  messageFin3 &&
                  !messageFin4 &&
                  verifyMessage) ||
                (bouyguesImage &&
                  messageFin2 &&
                  messageFin &&
                  messageFin5 &&
                  messageFin3 &&
                  !messageFin6 &&
                  !verifyMessage) ||
                (noImage &&
                  messageFin2 &&
                  messageFin &&
                  messageFin5 &&
                  messageFin3 &&
                  !messageFin4 &&
                  !verifyMessage)
              "
              class="ml-16"
            />
          </section>

          <section class="flex flex-col gap-3">
            <div
              class="flex items-center gap-6"
              v-if="
                messageFin7 &&
                FinalmesssageTime2 !=
                  'Prépare-toi à dégainer ton téléphone pour en profiter au plus vite 📞'
              "
            >
              <img
                :class="{
                  'opacity-0':
                    bouyguesImage &&
                    FinalmesssageTime2 !=
                      'Prépare-toi à dégainer ton téléphone pour en profiter au plus vite 📞' &&
                    messageFin3 &&
                    messageFin6 &&
                    messageFin7,
                }"
                class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                src="../assets/leo.png"
                alt="mascotteTigre"
              />
              <p class="chat_bubble_question animation_chat text-[1rem] p-2">
                {{ FinalmesssageTime3 }}
              </p>
            </div>
            <Chat_loader
              v-if="
                bouyguesImage &&
                FinalmesssageTime2 !=
                  'Prépare-toi à dégainer ton téléphone pour en profiter au plus vite 📞' &&
                messageFin3 &&
                messageFin6 &&
                !messageFin8
              "
              class="ml-16"
            />
          </section>

          <section
            class="flex flex-col gap-3"
            :class="{
              'mt-[-0.55rem]':
                FinalmesssageTime2 ==
                  'Prépare-toi à dégainer ton téléphone pour en profiter au plus vite 📞' &&
                bouyguesImage,
            }"
          >
            <div class="flex items-center gap-6" v-if="messageFin4">
              <img
                :class="{
                  'opacity-0':
                    bouyguesImage &&
                    messageFin7 &&
                    (verifyMessage || !verifyMessage),
                }"
                class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                src="../assets/leo.png"
                alt="mascotteTigre"
              />
              <p
                class="chat_bubble_question animation_chat p-2 mt-1 text-[1rem]"
              >
                Pour avoir la proposition la plus précise, connecte-toi à
                l’espace client de ton opérateur ou assure-toi d’avoir une
                facture sous la main.
              </p>
            </div>
            <Chat_loader
              v-if="
                bouyguesImage &&
                messageFin4 &&
                !messageFin7 &&
                (verifyMessage || !verifyMessage)
              "
              class="ml-16"
            />
          </section>

          <section class="flex flex-col gap-3 mt-[-0.35rem]">
            <div class="flex items-center gap-6" v-if="messageFin7">
              <img
                :class="{
                  'opacity-0':
                    bouyguesImage &&
                    messageFin10 &&
                    (verifyMessage || !verifyMessage) &&
                    messageFin4,
                }"
                class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                src="../assets/leo.png"
                alt="mascotteTigre"
              />
              <p
                class="chat_bubble_question animation_chat mt-1 p-2 text-[1rem]"
              >
                Belle journée à toi et profite à fond de ta nouvelle
                <span class="font-bold"> expérience mobile !</span> 😉
              </p>
            </div>
            <Chat_loader
              v-if="
                bouyguesImage &&
                messageFin4 &&
                messageFin7 &&
                !messageFin10 &&
                (verifyMessage || !verifyMessage)
              "
              class="ml-16"
            />
          </section>

          <section class="flex items-center gap-6" v-if="messageFin10">
            <img
              class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
              src="../assets/leo.png"
              alt="mascotteTigre"
            />
            <chatbotPartage />
          </section>
        </div>
      </div>
    </div>
    
    <!-- Modal de relance de l'utilisateur -->
    <relanceModal
      v-if="openRelanceModal"
      @close="closeRelanceModal()"
      :relance1="relance1"
      :relance2="relance2"
      :relance3="relance3"
    />
    <!-- Modal d'au revoir -->
    <GoodByeModal @close="closeGoodByeModal()" v-if="openGoodByeModal" />
    <Toctoc @close="closeGoodByeModal()" v-if="openToctocModal" />
    <PupUpAppel @close="closeGoodByeModal()" v-if="openPupUpAppelModal" />
    <!-- Modal de consentement aux cookies -->
    <cookiesModal
      class="cookiesAnimation"
      @close="closeCookiesModal()"
      v-if="openCookiesModal"
    />
    <img
      @click="showCookiesModal()"
      v-if="!openCookiesModal && showIconCookies"
      class="fixed bottom-[6%] lg:bottom-[8%] sm:bottom-[8%] md:bottom-[16%] w-10 md:w-12 lg:w-18 h-10 md:h-12 lg:h-18 left-0 -[10%]"
      src="../assets/tigre_cookies.png"
      alt="tigre_cookies"
    />
  </main>

  <!-- le pied de page de l'interface de conversation -->
  <chatFooter />

  <!-- Loader au chargement de la page -->
  <Initial_loader v-if="showInitialLoader" class="loaderStyle" />
</template>

<script setup>
/***
 * Importations des ressources nécessaires
 */
import { onMounted, ref, watch } from "vue";
// import config from "@/main.js";
// import axios from "axios";
// Importation du loader à afficher au chargement de la page
import Initial_loader from "@/components/First_loader.vue";
// Importation du loader à afficher lorsqu'un message est en cours de chargement
import Chat_loader from "@/components/chat_loader.vue";
import chatbotPartage from "@/components/chatbot/chatbotPartage.vue";
import chatHeader from "@/components/chatbot/chatbotHeader.vue";
import chatFooter from "@/components/chatbot/chatbotFooter.vue";
import relanceModal from "@/components/chatbot/RelanceModal.vue";
import cookiesModal from "@/components/chatbot/CookiesModal.vue";
import GoodByeModal from "@/components/chatbot/GoodByeModal.vue";
import Toctoc from "@/components/chatbot/Toctoc.vue";
import PupUpAppel from "@/components/chatbot/PupUpAppel.vue";

/********************  Modal de relance *********************/

/***
 * Déclaration des variables
 */
const relance1 = ref(false);
const relance2 = ref(false);
const relance3 = ref(false);
const openRelanceModal = ref(false);
const showIconCookies = ref(false);
let clearInactivity = ref(null);
let isDisabled = ref(true);

//données pour les formulaires
const emailError = ref("");
const numeroError = ref("");
const showError = ref("");

const email_client = ref(null);
const numero_client = ref(null);
const firstAgreement = ref(false);
const engagement = ref(null);
const cout = ref(null);
const type_forfait = ref(null);
const motivation = ref(null);

/***
 * Méthodes
 */

// Fermer  le modal de relance
const closeRelanceModal = () => {
  openRelanceModal.value = false;
};

/********************  Modal des cookies *********************/

/***
 * Déclaration des variables
 */
const openCookiesModal = ref(false);
setTimeout(() => {
  openCookiesModal.value = true;
}, 10000);

/**fin**/

// L'ensemble des fonctions suivantes sont pour gérer l'interaction des utilisateurs sur le bouton je découvre mon offre
const displayText = ref("Je découvre mon offre");
function handleInteraction() {
  if (isDisabled.value) {
    displayText.value = "Veuiller cocher la case des CGU";
  } else {
    displayText.value = "Je découvre mon offre";
  }
}

function handleMouseLeave() {
  if (!isDisabled.value && firstAgreement.value) {
    displayText.value = "Je découvre mon offre";
  } else if (isDisabled.value && !firstAgreement.value) {
    displayText.value = "Je découvre mon offre";
  }
}

watch(firstAgreement, (newVal) => {
  if (newVal) {
    isDisabled.value = false;
  } else {
    isDisabled.value = true;
  }
});

watch(isDisabled, (newVal) => {
  if (!newVal) {
    displayText.value = "Je découvre mon offre";
  }
});

///------fin------///

// Affiche le modal des cookies
const showCookiesModal = () => {
  openCookiesModal.value = true;
  showIconCookies.value = false;
};
// Fermer le modal des cookies
const closeCookiesModal = () => {
  openCookiesModal.value = false;
  showIconCookies.value = true;
};

/********************  Modal d'au revoir *********************/

/***
 * Déclaration des variables
 */
const openGoodByeModal = ref(false);
const openToctocModal = ref(false);
const openPupUpAppelModal = ref(false);

/***
 * Méthodes
 */

// Fermer le modal des cookies
const closeGoodByeModal = () => {
  openPupUpAppelModal.value = false;
  openGoodByeModal.value = false;
  openToctocModal.value = false;
  clearTimeout(clearInactivity.value);
  clearTimeout(setIntervalDelete.value);
};
/**fin**/

const bouyguesImage = ref(false);
const noImage = ref(false);
const messageFin = ref(false);
const messageFin2 = ref(false);
const messageFin3 = ref(false);
const messageFin4 = ref(false);
const messageFin5 = ref(false);
// const messageFinTadaam = ref(false);
const messageFin6 = ref(false);
const messageFin7 = ref(false);
const messageFin8 = ref(false);
const messageFin9 = ref(false);
const messageFin10 = ref(false);
const show1 = ref(true);
const show2 = ref(false);
const show3 = ref(false);
const showAnswerButton = ref(true);
const showQuestion = ref(false);
const messageFinal = ref(false);

setTimeout(() => {
  show2.value = true;
}, 4000);
setTimeout(() => {
  show3.value = true;
}, 6000);

const currentStep = ref(false);
const nextStep = () => {
  // Validation logic
  if (!email_client.value) {
    emailError.value = "Veuillez entrer un mail.";
  } else if (!isEmailValid(email_client.value)) {
    emailError.value = "Veuillez saisir une adresse e-mail valide.";
  } else {
    emailError.value = "";
  }

  if (!numero_client.value) {
    numeroError.value = "Veuillez entrer un numéro à 10 chiffres.";
  } else if (!isValidPhoneNumber(numero_client.value)) {
    numeroError.value =
      "Veuillez saisir un numéro de téléphone valide à 10 chiffres .";
  } else {
    numeroError.value = "";
  }

  // Check if all fields are valid
  if (
    isEmailValid(email_client.value) &&
    isValidPhoneNumber(numero_client.value)
  ) {
    currentStep.value++;
    showAnswerButton.value = true;
    showSuivantButton.value = false;
    return true;
  } else {
    currentStep.value = false;
    showAnswerButton.value = false;
    return false;
  }
};

const setIntervalDelete = ref(null);
const showSuivantButton = ref(true);
// variable de controle du scrool
const pageContainer = ref(null);
// Variable affichant ou non le loader initial
const showInitialLoader = ref(true);
// Variable affichant ou non les message du bot
const showBotAnswer = ref(false);
// Variable affichant ou non le loader lors du chargement d'un message
const beginMsg = ref([
  " Bonjour, c'est Léo ton bot mobile. 😊",
  "Tu souhaites un nouveau téléphone ? Restes avec moi jusqu'au bout ! 🤗",
  "Faisons connaissance... 😉",
]);
const endMsg = ref(null);
const firstQuestion = ref("Quelle marque de téléphone 📱 te ferait plaisir ?");
const suggested_answers = ref([
  { answer: "SAMSUNG", answerImg: require("@/assets/img/samsung.png") },
  { answer: "SAMSUNG Z-Flip", answerImg: require("@/assets/samsumg_flip.png") },
  { answer: "IPHONE", answerImg: require("@/assets/img/iphone1.png") },
  { answer: "REDMI", answerImg: require("@/assets/img/xiaomi.png") },
  { answer: "HONOR", answerImg: require("@/assets/img/honor.png") },
  { answer: "AUTRE", answerImg: require("@/assets/img/dot.png") },
]);

const answerChosen = ref(null);
// const conversation_id = ref(null);
// const visitor_id = ref(null);
const botAnswer = ref([]);
const isCodePostalForm = ref(false);
const isOfferForm = ref(false);
const showCGUmsg = ref(false);
const historyMessage = ref({});
const chatHistory = ref([]);
const count = ref(0);
// const modalOui = ref(false);
const offer = ref(null);
const weight = ref(0);
const goNext = ref(false);

const checkInactivity = () => {
  // Vérifier si le compteur d'inactivité a atteint la limite
  if (count.value >= 300) {
    // 300 secondes (5 minutes) d'inactivité, ajustez selon vos besoins
    openRelanceModal.value = true;
  }
};

// Utiliser setTimeout pour vérifier l'inactivité après un certain temps
const startInactivityCheck = () => {
  clearInactivity.value = setTimeout(checkInactivity, 300000);
};

// const provenance = localStorage.getItem('provenance');
// const beginDiscussion = (question, answer, provenance) => {
//   axios
//     .post(`${config.BASE_URL}/chatApi/init_discussion.php`, {
//       question: question,
//       answer: answer,
//       provenance: provenance,
//     })
//     .then((response) => {
//       conversation_id.value = response.data.conversation_id;
//       visitor_id.value = response.data.visitor_id;
//     });
// };

onMounted(() => {
  startInactivityCheck();
  resetActivityTimeout();

  setTimeout(() => {
    showInitialLoader.value = false;
  }, 2000);
  setTimeout(() => {
    setTimeout(() => {
      showQuestion.value = true;
      showBotAnswer.value = true;
    }, 6000);

    endMsg.value = "";
    historyMessage.value = {
      role: "bot",
      welcomeMsg: beginMsg.value,
      question: firstQuestion.value,
      suggested_answers: suggested_answers.value,
      affirmation: [],
    };

    return historyMessage;
  }, 2000);
});

// fonction qui gère le scroll automatique du chatbot
const scrollToBottom = () => {
  if (pageContainer.value) {
    pageContainer.value.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }
};

/**fonctions pour enregistrer les actions de l'utilisateur**/

// const tracking_action = (
//   conversation_id,
//   visitor_id,
//   question,
//   answer,
//   provenance
// ) => {
//   axios.post(`${config.BASE_URL}/chatApi/tracking_action.php`, {
//     conversation_id: conversation_id,
//     visitor_id: visitor_id,
//     question: question,
//     answer: answer,
//     provenance: provenance,
//   });
// };

// const end_discussion = (conversation_id, visitor_id, question, answer) => {
//   axios.post(`${config.BASE_URL}/chatApi/end_discussion.php`, {
//     conversation_id: conversation_id,
//     visitor_id: visitor_id,
//     question: question,
//     answer: answer,
//   });
// };

/**Fonctions gérant la validité des données du formulaire de découverte d'offre**/
const submitForm = () => {
  if (!firstAgreement.value) {
    showError.value = true;
    return false;
  } else {
    showError.value = "";
    return true;
  }
};

const isEmailValid = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const isValidPhoneNumber = (phoneNumber) => {
  const phoneRegex = /^(01|02|03|04|05|06|07|08|09)(?!.*00000)\d{8}$/;
  return phoneRegex.test(phoneNumber);
};

/**Fin**/

// const affirmation = ref("")
const showAffirmation = ref("");
const showAffirmation1 = ref(false);
const showAffirmation2 = ref(false);
const showAffirmation3 = ref(false);
const showAffirmation4 = ref(false);
const showAffirmation5 = ref(false);
const showAffirmation6 = ref(false);
const showAffirmation7 = ref(false);
const showAffirmationTadaam = ref(false);
const showAnswerLoader = ref(false);

//Cette fonction permet de renvoyer la question suivante et d'assurer le maintien du fil de la discussion
const getAnswerChosen = async (answer) => {
  clearInterval(setIntervalDelete.value);
  //le bloc suivant est utilisé pour savoir la logique à suivre après le formulaire de code postal
  const provenance = localStorage.getItem("provenance");
  console.log(provenance);
  if (answer == "Je découvre mon offre") {
    if (!email_client.value) {
      emailError.value = "Veuillez saisir une adresse e-mail valide.";
      return;
    } else if (!isEmailValid(email_client.value)) {
      emailError.value = "Le format de l'adresse e-mail est invalide.";
      return;
    } else {
      emailError.value = "";
    }

    if (!numero_client.value) {
      numeroError.value = "Veuillez entrer un numéro à 10 chiffres.";
      return;
    } else if (!isValidPhoneNumber(numero_client.value)) {
      numeroError.value = "Le format du numéro de téléphone est invalide.";
      return;
    } else {
      numeroError.value = "";
    }

    //le bloc suivant est utilisé pour savoir la logique à suivre après le formulaire de découverte offre
    if (submitForm()) {
      showQuestion.value = false;
      isOfferForm.value = false;
      showCGUmsg.value = false;

      //   axios
      //     .post(`${config.BASE_URL}/chatApi/index.php`, {
      //       email: email_client.value,
      //       telephone: numero_client.value,
      //       consentement: firstAgreement.value == true ? "Oui" : "Non",
      //       cout: cout.value,
      //       motivation: motivation.value,
      //       forfait: type_forfait.value,
      //       engagement: engagement.value,
      //       lead_id: conversation_id.value,
      //       provenance: localStorage.getItem("provenance"),
      //     })
      //     .then((response) => {
      //       console.log(response);
      //     });

      //   tracking_action(
      //     conversation_id.value,
      //     visitor_id.value,
      //     firstQuestion.value,
      //     answer,
      //     provenance
      //   );

      chatHistory.value.push(historyMessage.value);
      chatHistory.value.push({ role: "user", content: answer });
      localStorage.setItem("chatHistory", chatHistory);
      beginMsg.value = [];
      showAnswerLoader.value = true;
      showBotAnswer.value = false;
      setTimeout(() => {
        //charger l'étape suivante
        showAnswerLoader.value = false;
        showBotAnswer.value = true;
        showQuestion.value = false;
        messageFinal.value = true;
        weight.value++;
        botAnswer.value = [];

        switch (weight.value) {
          case 5:
            window.removeEventListener("mousemove", resetActivityTimeout);
            window.removeEventListener("keydown", resetActivityTimeout);
            firstQuestion.value = "Enquête en cours… 👮‍♀️";
            break;
        }

        if (firstQuestion.value.indexOf("Enquête en cours… 👮‍♀️") !== -1) {
          //   end_discussion(
          //     conversation_id.value,
          //     visitor_id.value,
          //     firstQuestion.value,
          //     answerChosen.value
          //   );
          window.removeEventListener("mousemove", resetActivityTimeout);
          window.removeEventListener("keydown", resetActivityTimeout);

          setTimeout(() => {
            if (answer == "Je découvre mon offre") {
              messageFin.value = true;
              showAffirmation7.value = false;
              showAffirmationTadaam.value = true;
              bouyguesImage.value = true;
              noImage.value = false;
              setTimeout(() => {
                getDiscussionTime();

                setTimeout(() => {
                  messageFin5.value = true;
                }, 3000);
                setTimeout(() => {
                  messageFin2.value = true;
                }, 6000);
                setTimeout(() => {
                  messageFin3.value = true;
                }, 9500);
                if (verifyMessage.value) {
                  setTimeout(() => {
                    messageFin4.value = true;
                  }, 12000);
                  setTimeout(() => {
                    messageFin7.value = true;
                  }, 15000);

                  setTimeout(() => {
                    messageFin10.value = true;
                    clearInterval(setIntervalDelete.value);
                    clearTimeout(clearInactivity.value);
                    window.removeEventListener(
                      "mousemove",
                      resetActivityTimeout
                    );
                    window.removeEventListener("keydown", resetActivityTimeout);
                    const counterFinal = ref(0);
                    setInterval(() => {
                      counterFinal.value++;
                      if (counterFinal.value == 15) {
                        openGoodByeModal.value = false;
                        openRelanceModal.value = false;
                        window.removeEventListener(
                          "mousemove",
                          resetActivityTimeout
                        );
                        window.removeEventListener(
                          "keydown",
                          resetActivityTimeout
                        );
                      }
                    }, 1000);
                  }, 17000);
                } else {
                  setTimeout(() => {
                    messageFin6.value = true;
                  }, 3000);
                  setTimeout(() => {
                    messageFin8.value = true;
                    // startTimerForToctoc();
                    window.removeEventListener(
                      "mousemove",
                      resetActivityTimeout
                    );
                    window.removeEventListener("keydown", resetActivityTimeout);
                  }, 8000);

                  setTimeout(() => {
                    messageFin4.value = true;
                  }, 15000);

                  setTimeout(() => {
                    messageFin9.value = true;
                  }, 18000); // 23000

                  setTimeout(() => {
                    messageFin10.value = true;

                    clearInterval(setIntervalDelete.value);
                    clearTimeout(clearInactivity.value);
                    const counterFinal = ref(0);
                    setInterval(() => {
                      counterFinal.value++;
                      if (counterFinal.value == 20) {
                        openGoodByeModal.value = false;
                        openRelanceModal.value = false;
                        window.removeEventListener(
                          "mousemove",
                          resetActivityTimeout
                        );
                        window.removeEventListener(
                          "keydown",
                          resetActivityTimeout
                        );
                      }
                    }, 1000);
                  }, 28000);
                }
              }, 2000);
            }
          }, 5000);
        }

        answerChosen.value = null;

        historyMessage.value = {
          role: "bot",
          welcomeMsg: [],
          question: firstQuestion.value,
          suggested_answers: suggested_answers.value,
          affirmation: botAnswer.value,
        };
        return historyMessage, isCodePostalForm, isOfferForm, offer;
      }, 1500);

      scrollToBottom();
      return historyMessage, isCodePostalForm, isOfferForm;
    } else {
      isOfferForm.value = true;
      showCGUmsg.value = true;
    }
  } else {
    showQuestion.value = false;

    if (
      firstQuestion.value == "Quelle marque de téléphone 📱 te ferait plaisir ?"
    ) {
      motivation.value = answer;
      //   beginDiscussion(firstQuestion.value, answer, provenance);
    } else {
      //   tracking_action(
      //     conversation_id.value,
      //     visitor_id.value,
      //     firstQuestion.value,
      //     answer,
      //     provenance
      //   );
    }

    answerChosen.value = answer;
    chatHistory.value.push(historyMessage.value);
    chatHistory.value.push({ role: "user", content: answer });
    localStorage.setItem("chatHistory", chatHistory);
    beginMsg.value = [];
    showAnswerLoader.value = true;
    showBotAnswer.value = false;
    setTimeout(() => {
      showAnswerLoader.value = false;
      showBotAnswer.value = true;

      if (goNext.value == true) {
        if (firstQuestion.value == "Note tes coordonnées juste ici 👇") {
          weight.value++;
        } else {
          weight.value = 5;
        }
      } else {
        weight.value++;
      }

      switch (answerChosen.value) {
        case "20Go":
        case "100Go":
        case "130Go":
        case "150Go":
        case "200Go":
        case "300Go":
          // case "Je ne sais pas":
          type_forfait.value = answerChosen.value;
          break;

        case "Moins 20 €":
        case "Entre 20 et 30 €":
        case "Entre 30 et 40 €":
        case "Plus de 40 €":
          cout.value = answerChosen.value;
          botAnswer.value = [
            "Très bien, nous avons des offres incroyables pour toi ! 🤩",
          ];

          break;
        case "Je ne sais pas":
          if (
            botAnswer.value ==
            (firstQuestion.value == "Tu as besoin de combien de data 📶 ?")
          ) {
            botAnswer.value = [
              "Très bien, nous avons des offres incroyables pour toi ! 🤩",
            ];
          } else {
            botAnswer.value = [];
          }
          if (firstQuestion.value == "Tu paies combien 💰 actuellement ?") {
            cout.value = answerChosen.value;
          }

          break;

        case "Oui":
        case "Non":
        case "NSP":
          botAnswer.value = [];
          engagement.value = answerChosen.value;
          break;
      }

      switch (weight.value) {
        case 1:
          firstQuestion.value = "Tu as besoin de combien de data 📶 ?";
          suggested_answers.value = [
            { answer: "20Go", answerImg: null },
            { answer: "100Go", answerImg: null },
            { answer: "130Go", answerImg: null },
            { answer: "150Go", answerImg: null },
            { answer: "200Go", answerImg: null },
            { answer: "300Go", answerImg: null },
            { answer: "Je ne sais pas", answerImg: null },
          ];
          break;
        case 2:
          firstQuestion.value = "Tu paies combien 💰 actuellement ?";
          suggested_answers.value = [
            { answer: "Moins 20 €", answerImg: null },
            { answer: "Entre 20 et 30 €", answerImg: null },
            { answer: "Entre 30 et 40 €", answerImg: null },
            { answer: "Plus de 40 €", answerImg: null },
            { answer: "Je ne sais pas", answerImg: null },
          ];
          break;
        case 3:
          firstQuestion.value = "Et, es-tu toujours engagé(e) stp ?";
          suggested_answers.value = [
            { answer: "Oui", answerImg: null },
            { answer: "Non", answerImg: null },
            { answer: "NSP", answerImg: null },
          ];
          break;
        case 4:
          botAnswer.value = [
            "Top ! Merci. ",
            "Casquette de détective activée 🧢 pour dénicher ton offre ! 🧐",
          ];
          showAnswerButton.value = false;
          firstQuestion.value = "Note tes coordonnées juste ici 👇";
          suggested_answers.value = [
            { answer: "Je découvre mon offre", answerImg: null },
          ];
          if (botAnswer.value[0] == "Top ! Merci. ") {
            showAffirmation1.value = false;
            showAffirmation2.value = false;
            showAffirmation3.value = false;
            showAffirmation4.value = false;
            showAffirmation5.value = true;
            setTimeout(() => {
              showAffirmation6.value = true;
            }, 1500);
            setTimeout(() => {
              showAffirmation7.value = true;
            }, 3000);
          }

          isOfferForm.value = true;
          answerChosen.value = null;

          historyMessage.value = {
            role: "bot",
            welcomeMsg: [],
            question: firstQuestion.value,
            suggested_answers: suggested_answers.value,
            affirmation: botAnswer.value,
          };
          scrollToBottom();
          break;
      }

      if (botAnswer.value.length > 0) {
        if (
          botAnswer.value[0] ==
          "Très bien, nous avons des offres incroyables pour toi ! 🤩"
        ) {
          showAffirmation.value = botAnswer.value[0];
          showAffirmation1.value = true;
          setTimeout(() => {
            showAffirmation2.value = true;
          }, 1500);
        }
      }
      if (firstQuestion.value == "Note tes coordonnées juste ici 👇") {
        setTimeout(() => {
          showQuestion.value = true;
        }, botAnswer.value.length * 4000);
      } else {
        setTimeout(() => {
          showQuestion.value = true;
        }, botAnswer.value.length * 1500);
      }

      if (firstQuestion.value.indexOf("découvrez votre offre") !== -1) {
        isOfferForm.value = true;
      }

      answerChosen.value = null;

      historyMessage.value = {
        role: "bot",
        welcomeMsg: [],
        question: firstQuestion.value,
        suggested_answers: suggested_answers.value,
        affirmation: botAnswer.value,
      };
      scrollToBottom();

      return historyMessage, isOfferForm;
    }, 1500);
  }
};

/**
 * Méthode affichant les messages en fonction de l'heure
 */
const FinalmesssageTime = ref("");
const FinalmesssageTime2 = ref("");
const FinalmesssageTime3 = ref("");
// const FinalmesssageTime4 = ref("");
const verifyMessage = ref(false);

// fonction permettant de contrôler l'affichage des messages de fin
function getDiscussionTime() {
  // *Récupérer l'heure de Paris*
  var date = new Date();
  date.toLocaleDateString("fr-FR", {
    timeZone: "Europe/Paris",
  });

  var heure = date.getHours();
  var minutes = date.getMinutes();
  var jour = date.getDay();

  // Fonction pour afficher le message
  function showMessageTime(message) {
    FinalmesssageTime.value = message;
  }

  // Déterminer le message à afficher en fonction de l'heure et du jour
  if (jour >= 1 && jour <= 7) {
    // Lundi au Vendredi
    if ((heure >= 10 && heure < 19) || (heure === 19 && minutes <= 45)) {
      // Afficher le message d'ouverture du service
      if (bouyguesImage.value) {
        showMessageTime(
          "Je passe la main à un humain, 🤵 il te rappellera d’ici quelques minutes…"
        );
        verifyMessage.value = true;
        FinalmesssageTime2.value =
          "Prépare-toi à dégainer ton téléphone pour en profiter au plus vite 📞";
        FinalmesssageTime3.value =
          "Pour avoir la proposition la plus précise, connecte-toi à l'espace client de ton opérateur ou assure-toi d'avoir une facture sous la main.";
        window.removeEventListener("mousemove", resetActivityTimeout);
        window.removeEventListener("keydown", resetActivityTimeout);
      } else {
        showMessageTime(
          "Je passe la main à un humain, 🤵 il te rappellera d’ici quelques minutes…"
        );
        FinalmesssageTime2.value =
          "Prépare-toi à dégainer ton téléphone pour en profiter au plus vite 📞";
        FinalmesssageTime3.value =
          "Pour avoir la proposition la plus précise, connecte-toi à l'espace client de ton opérateur ou assure-toi d'avoir une facture sous la main.";
        window.removeEventListener("mousemove", resetActivityTimeout);
        window.removeEventListener("keydown", resetActivityTimeout);
      }
    } else {
      if (bouyguesImage.value) {
        showMessageTime(
          "Je passe la main à un humain, 🤵 il te rappellera d’ici quelques minutes…"
        );
        verifyMessage.value = true;
        FinalmesssageTime2.value =
          "Prépare-toi à dégainer ton téléphone pour en profiter au plus vite 📞";
        FinalmesssageTime3.value =
          "Pour avoir la proposition la plus précise, connecte-toi à l'espace client de ton opérateur ou assure-toi d'avoir une facture sous la main.";
        window.removeEventListener("mousemove", resetActivityTimeout);
        window.removeEventListener("keydown", resetActivityTimeout);
      } else {
        showMessageTime(
          "Je passe la main à un humain, 🤵 il te rappellera d’ici quelques minutes…"
        );
        FinalmesssageTime2.value =
          "Prépare-toi à dégainer ton téléphone pour en profiter au plus vite 📞";
        FinalmesssageTime3.value =
          "Pour avoir la proposition la plus précise, connecte-toi à l'espace client de ton opérateur ou assure-toi d'avoir une facture sous la main.";
        window.removeEventListener("mousemove", resetActivityTimeout);
        window.removeEventListener("keydown", resetActivityTimeout);
      }
    }
  }
}

const resetActivityTimeout = () => {
  count.value = 0;
  // Réinitialiser le minuteur de vérification d'inactivité
  clearTimeout(setIntervalDelete.value);
  // Redémarrer le minuteur de vérification d'inactivité
  setIntervalDelete.value = setInterval(() => {
    count.value++;
    switch (count.value) {
      case 45:
        openRelanceModal.value = true;
        relance1.value = true;
        relance2.value = false;
        relance3.value = false;
        break;

      case 90:
        openRelanceModal.value = true;
        relance2.value = true;
        relance3.value = false;
        relance1.value = false;
        relance3.value = false;
        break;

      case 420:
        openRelanceModal.value = true;
        relance2.value = false;
        relance1.value = false;
        relance3.value = true;
        clearInterval(setIntervalDelete.value);
        break;
    }
  }, 1000);
};

// Vérifier si une activité est en cours sur la page
window.addEventListener("mousemove", resetActivityTimeout);
window.addEventListener("keydown", resetActivityTimeout);
</script>

<style src="./style.css"></style>
