import { createRouter, createWebHistory } from "vue-router";
import PolitiqueCookies from "../views/PolitiqueCookies.vue";
import Conversation from "../views/Conversation.vue";
import ConversationOffre from "../views/ConversationOffre.vue";
import PolitiqueConfidentialite from "../views/PolitiqueConfidentialite.vue";
import MentionsLegales from "@/views/MentionsLegales.vue";
import ConditionsGenerales from "@/views/ConditionsGenerales.vue";
// import axios from "axios";
const router = createRouter({
  history: createWebHistory(), //
  routes: [
    {
      path: "/",
      name: "internet",
      meta: {
        title: "Bot-Internet",
        requiresAuth: false,
      },
      component: Conversation,
      props: (route) => ({
        flag: route.params.flag,
      }),
    },
    {
      path: "/offre",
      name: "offre",
      meta: {
        title: "Bot-Mobile",
        requiresAuth: false,
      },
      component: ConversationOffre,
      props: (route) => ({
        flag: route.params.flag,
      }),
    },

    {
      path: "/:flag",
      name: "internetWithFlag",
      meta: {
        title: "Bot-Internet",
        requiresAuth: false,
      },
      component: Conversation,
      props: (route) => ({
        flag: route.params.flag,
      }),
    },

    {
      path: "/politiqueConfidentialite",
      name: "politiqueConfidentialite",
      meta: {
        title: "politique-confidentialité",
        requiresAuth: false,
      },
      component: PolitiqueConfidentialite,
    },
    {
      path: "/politiquegestioncookies",
      name: "politiquegestioncookies",
      meta: {
        title: "politique-gestion-cookies",
        requiresAuth: false,
      },
      component: PolitiqueCookies,
    },

    {
      path: "/mentionslegales",
      name: "mentionslegales",
      meta: {
        title: "Mentions légales",
      },
      component: MentionsLegales,
      props: true,
    },
    {
      path: "/conditionsgenerales",
      name: "conditionsgenerales",
      meta: {
        title: "Conditions Générales d'utilisations",
      },
      component: ConditionsGenerales,
      props: true,
    },
  ],
  transition: {
    name: "fade",
    mode: "out-in",
  },
  strict: true,
});

window.addEventListener("beforeunload", function () {
  localStorage.removeItem("provenance");
});

router.beforeEach(async (to, from, next) => {
  if (to.params.flag) {
    localStorage.setItem("provenance", to.params.flag);
    next({ name: "internet" });
  } else {
    next();
  }
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
