<template>
    <div class="bg-sky-700 p-1 w-full text-[#ccfed9]  fixed bottom-0 lg:left-0 lg:right-0">

        <div class="flex flex-row items-center justify-between gap-1 md:p-2 lg:p-2">
            <a target="_blank" href="/conditionsgenerales"
                class=" block text-center text-[0.8rem] md:text-[1rem]  transition-all hover:underline hover:text-blue-500">Conditions
                générales d'utilisation</a>
            <a target="_blank" href="/mentionslegales"
                class=" block text-center text-[0.8rem] md:text-[1rem] transition-all hover:underline hover:text-blue-500">
                Mentions légales</a>
            <a target="_blank" href="/politiqueConfidentialite"
                class="  block text-center text-[0.8rem] md:text-[1rem] transition-all hover:underline hover:text-blue-500">
                Politique de
                confidentialité
            </a>
            <a target="_blank" href="/politiquegestioncookies"
                class=" block text-center text-[0.8rem] md:text-[1rem] transition-all hover:underline hover:text-blue-500">
                Gestion des
                Cookies
            </a>

        </div>
    </div>
</template>

<script setup>

</script>

<style></style>